window.addEventListener('heyflow-screen-view', (event) => {
     console.log('heyflow screen view:', event.detail);
     
     const startDateErkrath = document.querySelectorAll('[data-variable="start_date_erkrath"]');
     
     fetchCourseData('BE-Fahrlehrer', 'Erkrath')
       .then(data => {
           startDateErkrath.forEach(node => {
                node.textContent = data.startdate;
           })
       })
       .catch(error => console.error(error));
     
     console.log(startDateErkrath.textContent);  
       
     const startDateWesel = document.querySelectorAll('[data-variable="start_date_wesel"]');
     
     fetchCourseData('BE-Fahrlehrer', 'Wesel')
       .then(data => {
           startDateWesel.forEach(node => {
                node.textContent = data.startdate;
           })
       })
       .catch(error => console.error(error));  
       
       
     const startDateRecklinghausen = document.querySelectorAll('[data-variable="start_date_recklinghausen"]');
     
     fetchCourseData('BE-Fahrlehrer', 'Recklinghausen')
       .then(data => {
           startDateRecklinghausen.forEach(node => {
                node.textContent = data.startdate;
           })
       })
       .catch(error => console.error(error));     
       
     const startDateBerlin = document.querySelectorAll('[data-variable="start_date_berlin"]');
     
     fetchCourseData('BE-Fahrlehrer', 'Berlin')
       .then(data => {
           startDateBerlin.forEach(node => {
                node.textContent = data.startdate;
           })
       })
       .catch(error => console.error(error));          
});

window.addEventListener('heyflow-submit', (event) => {
     console.log('heyflow submit:', event.detail);
});

function fetchCourseData(course, location) {
    const url = new URL('https://d2q8i1p0beenyo.cloudfront.net/get_next_course');
    url.searchParams.set('course', course);
    url.searchParams.set('location', location);
    
    return fetch(url).then(response => response.json());
}